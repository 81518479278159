.brands-retailers-container {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
    flex-direction: column; 
  justify-content: center; 
  align-items: center;
    color: #333;
  }
  .brands-retailers-container h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #235c47;
    line-height: 1.3; 
  }
  
  .brands-retailers-container p {
    font-size: 1.2rem;
  }

.container {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    padding: 20px;
    overflow: hidden;
    gap: 20px; 
  }
  

  .left-side {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .left-side h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .left-side p {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .left-side ul {
    list-style: none;
    padding-left: 0;
  }
  
  .left-side ul li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  

  .right-side {
    flex: 1; 
    max-width: 70%; 
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10%;
    right: 0%;
    align-items: center;
    overflow: hidden;
  }
  .right-2{
    transform: translateY(100px) rotateY(90deg); 
    opacity: 0;
    
    animation: slideUpSpin 2s ease-out forwards;
  }

  @keyframes slideUpSpin {
    0% {
      transform: translateY(100px) rotateY(90deg);
      opacity: 0; 
    }
    50% {
      transform: translateY(0) rotateY(45deg); 
      opacity: 0.7;
    }
    100% {
      transform: translateY(0) rotateY(0); 
      opacity: 1; 
    }
  }
  
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .left-side,
    .right-side {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }
  
    .right-side {
      height: 300px; 
    }
  }
  