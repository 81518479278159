.projects-div-border {
    box-shadow: none;
    background-color: #151515;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 1px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.projects-div-border:hover {
    outline: solid #151515;
    box-shadow: 0px 4px 20px rgba(0, 128, 128, 0.6); 
}


.projects-div-orbs {
    background-color: var(--new-nav-color);
    color: #f20909;
    border-radius: 16px 16px 0 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem 0 0;
    text-decoration: none;
    transition: all 0.3s;
    display: block;
    position: relative;
    overflow: hidden;
}


.mac-dots-group {
    grid-column-gap: 0.6rem;
    grid-row-gap: 0.6rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    display: flex;
}


.mac-dot {
    background-color: #393939; 
    border-radius: 100%;
    width: 0.75rem;
    height: 0.75rem;
    transition: background-color 0.3s; 
}


.projects-div-border:hover .mac-dot.red {
    background-color: #ff5f57;
}

.projects-div-border:hover .mac-dot.yellow {
    background-color: #ffbd2e; 
}

.projects-div-border:hover .mac-dot.green {
    background-color: #27c93f; 
}


.portfolio-image-wrapper {
    width: 100%;
    padding-top: 56%;
    position: relative;
   
    overflow: hidden;
}


.portfolio-image {
    object-fit: contain ;
    object-position: 20% 0%;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0%;
}


.projects-details-wrapper {
    background-color: #151515;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 1.5rem;
    display: flex;
}


.heading-style-h4 {
    font-size: 1.7rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.5;
}

.projects-tag-group {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}

.icon-embed-xsmall {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
}
@media (max-width: 768px) {
    .projects-div-border {
        box-shadow: none;
        background-color: #151515;
        border-radius: 16px;
        min-width: 30%;
        height: 100%;
        min-height: 360px;
        padding: 1px;
        transition: background-color 0.3s, box-shadow 0.3s;
    }
    .projects-details-wrapper {
      
        padding: 0.625rem;
     
    }
    .portfolio-image-wrapper {
        padding-top: 56%;
        position: relative;
        overflow: hidden;
        margin-left: 35px;
    }
    .heading-style-h4 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 1.5;
    }
    .icon-embed-xsmall {
        display: none;
    }
}