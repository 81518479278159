.get-started-section {
  max-width: 1280px; 
  margin: 0 auto; 
  padding: 24px; 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  position: relative; 
  margin-bottom: 100px;
  background-color: rgba(0, 0, 0, 0.821); ; 
}
.get-started-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1; 
}

.get-started-title {
  text-align: center;
  letter-spacing: -1px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Ubuntu, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  line-height: 1.4;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px; 
}

.button {
  padding: 8px 16px; 
  border-radius: 8px;
  cursor: pointer;
  margin: 0 4px; 
  transition: background-color 0.3s, color 0.3s; 
}

.button.jyver {
  background-color: #edf2f7; 
  color: #4a5568; 
}

.button.partner {
  background-color: #edf2f7;
  color: #4a5568;
}

.button.selected {
  background-color: #38b2a0;
  color: white;
}

.gradient-background {
  background: linear-gradient(to bottom right,  #141414 80%, #02443a 40%);
  border-radius: 12px; 
  padding: 24px; 
 
}

.columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px; 
}

@media (min-width: 768px) {
  .columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  padding: 16px; 
  border-radius: 12px; 
 
}

.icon {
  font-size: 2.5rem; 
  margin-bottom: 8px; 
  color: white; 
}

.column-title {
  color: white; 
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
}

.column-subheading {
  color: white; 
  margin-bottom: 0;
  line-height: 2;
  color: #f9fbfd99;
  margin-bottom: 8px; 
}
