
.custom-button {
  background-color: #E54260;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 0.875rem; 
  padding: 0.5rem 1rem; 
  text-align: center;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #52a689; 
}

.custom-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(82, 166, 137, 0.5); 
}
