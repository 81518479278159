.get-started-wrapper {
  background-image: url('../Attachments/DARK\ BACKGROUND\ \(1\).png'), linear-gradient(rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.135));; 
  background-size: fill;
  background-position: center center; 
  background-repeat: repeat; 
  padding: 50px 0; 
  color: white; 
  background-blend-mode: color;
}



