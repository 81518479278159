
.stacking-row {
  box-shadow: none;
  top: 100px;
  background-color: #141414;
  background-image: radial-gradient(circle farthest-side at 0 0, #17414d2b, #0000 60%), linear-gradient(#0f0e0ed9, #000000), url(https://cdn.prod.website-files.com/64c9523…/65d503a…_Grainy%20Effect.png);
  background-position: 0 0, 0 0, 0 0;
  border: 1px solid #ffffff26;
  border-radius: 16px;
  margin-bottom: 6rem;
  padding: 2rem;
  height: 700px;
  position: sticky;
  color: white;
  display: flex;   
  flex-direction: column;
  align-items: center; 
  
}
.dark-overlay {
  position: absolute;
  top: -1;
  left: -1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.049);
  border-radius: 16px; 
  z-index: 0;
}

.text-overlay {
  position: relative;
  z-index: 1; 
}

.stacking-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  pointer-events: none;
}

.darken-row::after{
  background: rgb(0 0 0 /63%); 
} 

.stacking-container{
padding: 6rem;
}
.text-overlay{
  position: absolute;
  top: 25%;
  left: 45%;
}
.text-overlaytwo{
  position: absolute;
  top: 25%;
  left: 6%;
  z-index: 1;
  right: 45%;
}
.stacking-row.stacked {
  position: sticky;
  top: 100px;
  transform: translateY(0);
  z-index: 10;
}

.header-image-container {
  display: flex;
  flex-direction: row;  
  justify-content: center; 
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.left {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #008080; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  margin-bottom: 10px;
}

.subheading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #008080; 
  border-radius: 50%;
  margin-right: 8px;
}

.subheading-text {
  font-size: 1rem;
  font-weight: 500;
}

.bold-heading {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.right {
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;  
}

.right img {
  max-height: 300px;
  border-radius: 10px;
}

.line-container {
  width: 100%;
  margin: 20px 0;
}

.line-separator {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.body-container {
  width: 100%;
  text-align: center;
}

.body-text {
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
}

@media (max-width: 768px) {
  .stacking-row {
    position: sticky;
    top: 30px;
    transform: translateY(0);
    z-index: 10;
  }
  .stacking-container{
      padding: 1rem;
       }
  .header-image-container {
    flex-direction: column; 
    text-align: center;
  }

  .left, .right {
    width: 100%;
    text-align: center;
  }

  .right img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .number-header {
    font-size: 2rem;
  }

  .subheading-text {
    font-size: 1rem;
  }

  .bold-heading {
    font-size: 1.5rem;
  }

  .stacking-row.stacked {
    top: 40px;
    z-index: 10;
  }

  .body-text {
    font-size: 1.1rem;
  }
  .bodyMedium{
    color: #f9fbfdeb;
  }

  .icon-circle {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
  .text-overlay{
    position: absolute;
    top: 25%;
    left: 0%;
    padding: 20px;
  }
  .text-overlaytwo{
    position: absolute;
    top: 25%;
    right: 0%;
    padding: 20px;
  }
  .headings{
    font-size: 2.8rem;
  }
  
}
