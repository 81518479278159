.download-text{
    font-size: 4rem;
    line-height: 4.8rem;
}
.textcontainer{
    max-width: 48rem;
}
@media (max-width: 768px) {
    .download-text{
        font-size: 2.5rem;
        line-height: 2.8rem;
    }
.download-container{
    width: 90%;
}
    .text-base{
        font-size: 0.9;
    }
}