.grayArea{
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
    background-size: auto, auto, auto;
    border: 1px solid #ffffff26;
    border-radius: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    place-items: start;
    padding: 2rem;
    margin-bottom: 2rem;
    display: grid;
}
.top-margin{
    margin-top: -12%;
}

h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Ubuntu, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    line-height: 1.5;
}
.text-sub{
  font-size: 1rem;
}
p{
    color: #f9fbfd99;
    margin-bottom: 0;
    line-height: 2;
  
}
.grayArea {
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
    background-size: auto, auto, auto;
    border: 1px solid #ffffff26;
    border-radius: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    place-items: start;
    padding: 2rem;
    margin-bottom: 2rem;
    display: grid;
  }
  
  @media (max-width: 767px) {
    .grayArea {
      grid-template-columns: 1fr;
      grid-column-gap: 1rem;
      grid-row-gap: 2rem;
    }
  
    .text-lg {
      font-size: 14px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.25rem;
    }
  
    p {
      font-size: 12px;
    }
  
    .w-16 {
      width: 3rem;
      height: 3rem;
    }
  }
  
  @media (min-width: 768px) {
    .grayArea {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 3rem;
      grid-row-gap: 4rem;
    }
  
    .text-lg {
      font-size: 16px;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    h3 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 14px;
    }
  
    .w-16 {
      width: 4rem;
      height: 4rem;
    }
  }
  
  @media (min-width: 1024px) {
    h2 {
      font-size: 2.5rem;
    }
  
    h3 {
      font-size: 1.75rem;
    }
  
    p {
      font-size: 16px;
    }
  }
  