.tab-pane-tab {
    background-position: 0 0, 0 0, 0 0;
    background-size: auto, auto, auto;
    border-radius: 16px;
    padding: 2rem;
    max-width: 40%;
    min-width: 40%;
}
.benefits_tab_image-wrapper {
    background-position: 0 0, 0 0;
    background-size: auto, auto;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    display: flex
;
}
.benefits-img{
    width: 65%;
    vertical-align: middle;
    max-width: 65%;
}
.benefits_button {
    background-color: #14141462;
    background-image: radial-gradient(circle farthest-side at 0% 0%, var(--radial-blue), transparent 60%), linear-gradient(to bottom, #141414e6, #141414e6), url(https://cdn.prod.website-files.com/64c9523…/65d503a…_Grainy%20Effect.png);
    color: #f9fbfdb3;
    border: 1px solid #343434;
    align-self: center;
    padding: 1rem;
    margin-bottom: 10px;
    transition: all .2s;
    position: relative;
}
.why-head{
    color:#38b2ac ;
}
.wrapper-container{
    margin-left: 10%;
}
.benefits_button.bg-green-500 {
    background-color: #38b2ac;  
    border-color: #38b2ac;     
}

.benefits_button.bg-blue-500 {
    background-color: #00000000;  
    border-color: #d7d9dc3b;      
}



.benefits_button.border-teal-500 {
    border: 2px solid #38b2ac;  
}
@media (max-width: 768px) {
    .tab-pane-tab {
        min-width: 100vw;
        
    }
    .wrapper-container{
        margin-left: 0%;
    }
    .why-head{
        font-size: 28px;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .tab-pane-tab {
        min-width: 48vw;
        
    }
    .wrapper-container{
        margin-left: 10%;
    }
    .why-head{
        font-size: 28px;
    }
  }