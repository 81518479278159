.carousel-container {
  width:100%;
  overflow: hidden;
  position: relative;
  background-color: #000000;
}

.carousel-container::before,
.carousel-container::after {
  content: '';
  position: absolute;
  top: 0;
  width: 200px; 
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.triangle-background {
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.carousel-container::before {
  left: 0;
  width: 30%;
  background: linear-gradient(to right, #000000, transparent);
}

.carousel-container::after {
  right: 0;
  width: 30%;
  background: linear-gradient(to left, #000000, transparent);
}

.slide-track {
  display: flex;
  width: calc(150px * 7 * 8); 
  animation: scroll 40s linear infinite;
  will-change: transform;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 250px;
  margin: 20px;
  flex-shrink: 0;
}

.slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}




@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@media (max-width: 992px) {
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin: 30px;
    flex-shrink: 0;
  }
}