
.help-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border-radius: 20px;
  transform: translateY(100px); 
  opacity: 0; 
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}


.help-section.slide-up {
  transform: translateY(0); 
  opacity: 1; 
}

.images{
  padding: 30px;
  margin: auto;
  max-width: 55%;
  max-height: 55%;
}

.left-section {
  flex: 1;
  color: white;
  position: sticky; 
  top: 100px;
  min-height: 300px;
  z-index: 10; 
  text-align: left;
  padding: 20px 20px 0px 20px;
  
}
.header {
  margin-top: 0px;
  margin-bottom: 100px;
  font-family: Formular, sans-serif;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.subtext {
  font-size: 1rem;
  color: #666;
}


.left-section .main-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Formular, sans-serif;
  font-size: 6em;
  line-height: 0.9em;
  font-weight: 500;
  letter-spacing: -0.08em;
}



.right-section {
  position: relative;
  width: 49%;

}


.columns-container {
  height: fit-content; 
 
}


.columns-container::-webkit-scrollbar {
  display: none; 
}

.chapter-anim-card {
  width: 100%; 
  height: 300px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  margin-bottom: 1.39em;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;

}
.wrapping{
  padding: 150px 10px ;
  position: relative;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.435); ;
 
}
.fade-out {
  transition: transform 0.60s ease-out, opacity 0.5s ease-out; 
}

.fade-in {
  transition: transform 0.45s ease-out, opacity 0.5s ease-out; 
}




.column-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  align-items: flex-start;
}


.column-left .subtext {
  font-size: 0.80rem;
  color: #666;
  margin-top: 5px;
}


.column-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-right img {
  width: 300px; 
  border-radius: 5px;
  max-height: 220px;
}

.chapter-anim-card {
  position: relative;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5426083; 
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease; 
  border-radius: 8px;
}


.chapter-anim-card:hover .overlay {
  opacity: 1; 
}


.overlay-text {
  text-align: center; 
  padding: 10px;
}

.wrapping {
  padding: 200px 10px;
  position: relative;
  transition: opacity 0.5s ease, transform 0.5s ease; 
  opacity: 0; 
  transform: translateY(50px);
}

.fade-in {
  opacity: 1; 
  transform: translateY(0); 
}

.fade-out {
  opacity: 0; 
}

@media (max-width: 1400px) {
  .help-section {
    flex-direction: row; 
    align-items: center;
    padding: 10px;
  }

  .left-section {
    position: static;
    text-align: center; 
    padding: 10px;
    min-height: 20px;
    width: 100%; 
    margin-bottom: 20px; 
  }
  .header{
    margin-bottom: 0px;
  }
  .images{
    display: none;
  }

  .left-section .main-title {
    font-size: 2.5em; 
  }

  .right-section {
    width: 100%; 
  }

  .chapter-anim-card {
    flex-direction: column; 
    height: auto; 
  }

  .column-left {
    margin-left: 0; 
    align-items: center; 
    text-align: center;
  }

  .column-right img {
    width: 100%;
    max-height: unset; 
  }
  
  .columns-container {
    overflow-x: hidden; 
  }

  .wrapping {
    padding: 50px 10px; 
  }
}
