.count-image {
    width: 700px;
  }
  
  .count-hold {
    background-color: rgb(0, 0, 0); 
    padding: 50px 100px 100px;
  }
  
  .count-text {
    font-size: 2.25rem;
    color: #9b9b9b;
  }
  
  .heading {
    color: #9b9b9b; 
  }
  
  .img-holder {
    margin: 0;
  }
  
  @media (max-width: 992px) {
    .count-image {
      width: 320px;
    }
  
    .object-cover {
      object-fit: contain !important; 
    }
  
    .count-hold {
      background-color: rgb(0, 0, 0); 
      padding: 10px;
    }
  
    .count-text {
      font-size: 1.875rem; 
    }
  
    .heading {
      padding: 0;
      margin-bottom: 0;
    }
  
    .img-holder {
      margin: 0;
    }
  }
  