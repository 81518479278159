.animated-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-image: url('../Attachments/Supermarket_shelves.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1;
    transition: background-color 0.5s ease, opacity 0.5s ease; 
  }
  .fade-out {
    opacity: 0; 
}

.fade-in {
    opacity: 1; 
}
  
  .animated-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }
  
  .animated-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 180px;
    background: linear-gradient(to top, #000000 20%, rgba(0, 0, 0, 0) 100%);
    z-index: 5;
  }
  
  .animated-image {
    position: absolute;
    left: 0;
    bottom: -50px;
    transition: transform 1s ease-in-out, opacity 0.2s ease-in-out;
    z-index: 2;
  }
  
  .animated-image.in-view {
    opacity: 1;
    transform: translateY(-45px);
  }
  
  .animated-image.out-of-view {
    transform: translateY(50px);
  }
  .app-store-links {
    display: flex;
    position: absolute;
    top: 75%;
    right: 1%;
    width: 40%;
    z-index:10;
    opacity: 1; 
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;}
  
  .app-store-icon {
  width: 140px;
  }
  
  .animated-shape {
    position: absolute;
    left: 20%;
    bottom: -50px;
    width: 90%;
    height: 100%;
    background-color: rgb(0 0 0);
    clip-path: polygon(100% 20%, 100% 100%, -80% 100%);
    transition: transform 1.5s ease-in-out, opacity 0.3s ease-in-out;
    z-index: 1;
  }
  
  .animated-shape.in-view {
    opacity: 1;
    transform: translateY(-45px);
  }
  
  .animated-shape.out-of-view {
    transform: translateY(50px);
  }
  

 
  
  .animated-shape.in-view .header-text,
  .animated-shape.in-view .sub-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animated-shape.out-of-view .header-text,
  .animated-shape.out-of-view .sub-text {
    opacity: 0;
    transform: translateY(50px);
  }
  .header-text {
    color: #33ab8f; 
    font-size: 4rem;
    text-align: right;
    font-family:Arial, Helvetica, sans-serif;
    margin-right: 20px;
    letter-spacing: 10px;
    position: absolute;
    top: 45%;
    right: 17%;
    line-height: 72px;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0; 
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
  
  .sub-text {
    color: #ffffff; 
    font-size: 15px;
    text-align: right; 
    font-family: 'Bauhaus93', sans-serif;
    position: absolute;
    top: 65%;
    right: 20%;
    width:35%;
    z-index:2;
    opacity: 0; 
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
  
  .animated-shape.in-view .header-text,
  .animated-shape.in-view .sub-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animated-shape.out-of-view .header-text,
  .animated-shape.out-of-view .sub-text {
    opacity: 0;
    transform: translateY(50px);
  }
  @media (max-width: 992px) {
    .animated-shape {
      position: absolute;
      left: 0px;
      bottom: -100px;
      width: 120%;
      top:0;
      height:65%;
      background-color: rgb(0 0 0);
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%);

      transition: transform 1.5s ease-in-out, opacity 0.3s ease-in-out;
      z-index: 1;
  }
  .header-text {
    color: #33ab8f;
        font-size: 3rem;
        text-align: left;
        font-family: 'Orbitron', sans-serif;
        margin-right: 20px;
        letter-spacing: 10px;
        position: absolute;
        top: 100px;
        left: 2%;
        line-height: 50px;
        transform: translateY(-50%);
        z-index: 999;
        opacity: 0;
        transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }
    .sub-text {
  color: #ffffff;
  font-size: 15px;
  text-align: left;
  font-family: 'Bauhaus93', sans-serif;
  position: absolute;
  top: 40%;
  width: 40%;
  z-index: 999;
  opacity: 0;
  left: 2%;
  transition: opacity s ease-in-out, transform 1s ease-in-out;
}
.animated-image.in-view {
  opacity: 1;
  transform: translateY(-45px);
}

.animated-image.out-of-view {
  transform: translateY(-50px);
 
  transition: opacity s ease-in-out, transform 1s ease-in-out;
}
.animated-shape.in-view {
  opacity: 1;
  transform: translateY(-45px);
}


.animated-shape.out-of-view {
  transform: translateY(-145px);
}
}
@media (max-width: 720px) {
  .sub-text {
    color: #ffffff;
    font-size: 14px;
    text-align: left;
    font-family: 'Bauhaus93', sans-serif;
    position: absolute;
    top: 45%;
    width: 37%;
    z-index: 999;
    opacity: 0;
    left: 2%;
  }
  .animated-shape {
    position: absolute;
    left: 0px;
    bottom: -100px;
    width: 160%;
    top:0;
    height:65%;
    background-color: rgb(0 0 0);
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);

    transition: transform 1.5s ease-in-out, opacity 0.3s ease-in-out;
    z-index: 1;}
}

