
.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
    overflow-x: hidden;
    background-color: #000000; 
    position: relative;
}

.text-content {
    flex: 1;
    margin-left: 30px;
    opacity: 0;
    transform: translateX(50px); 
    transition: opacity 1s ease, transform 1s ease;
}

.text-content.visible {
    opacity: 1;
    transform: translateX(0);
}


.header-title {
    font-size: 2.5rem;
    color: #9b9b9b;
    margin-bottom: 1rem;
}

.subtext {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 0.5rem;
}


.buttons-container {
    margin-top: 1.5rem;
}

.btn-primary {
    background-color: #E54260;
    color: #9b9b9b;
    border-radius: 10px;
    border: none;
    padding: 0.75rem 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.btn-secondary {
    background-color:  #004d40;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
}


.image-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
    opacity: 0;
    transform: translatey(-4rem);
    transition: opacity 0.8s ease, transform 1s ease;
}


.image-content.visible {
    opacity: 1;
    transform: translateX(0);
}


.half-phone {
    width: 90%;
    object-fit: cover;
    position: absolute;
    right: -4rem;
    top: 50%;
    transform: translateY(-50%);
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  gap: 40px; 
}

.buttons-container .blob-button {
  flex: 1; 
}

@media (max-width: 576px) {
  .header-section {
    align-items: center;
    padding: 1rem; 
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    gap: 10px; 
  }
  
  .buttons-container .blob-button {
    flex: 1; 
  }
  .text-content {
    margin-left: 0;
    text-align: center;
  }

  .header-title {
    font-size: 2rem;
  }

  .subtext {
    font-size: 1rem;
  }


  .btn-primary, .btn-secondary {
    width: 100%;
    margin-bottom: 1rem;
  }

  .image-content {
    margin-top: 2rem;
    padding-top: 1rem;
    justify-content: center;
    transform: translateY(0); 
  }

  .half-phone {
    width: 80%; 
    right: 0;
    transform: translateY(0); 
  }
}


@media (max-width: 768px) {
  .header-section {
   
    padding: 1rem;
  }

  .text-content {
    margin-left: 0;
    text-align: center;
  }

  .header-title {
    font-size: 2.2rem;
  }

  .subtext {
    font-size: 1.1rem;
  }

  .image-content {
    margin-top: 2rem;
    justify-content: center;
    transform: translateY(0);
  }
  .btn-primary {
    background-color: #E54260;
    color: #9b9b9b;
    border-radius: 10px;
    border: none;
    padding: 0.75rem 1.5rem;
    margin-right: 0rem;
    cursor: pointer;
}
  .half-phone {
    width: 70%; 
    right: 0;
  }
}
