.video-container {
    position: relative;
    overflow: hidden;
    border: 0px solid #5e6e80;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: CENTER;
    min-height: 70vh;
  }
  .video-container::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }
  .video-background {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    height: 100%;
    min-height: 100%;
    z-index: -1;
    object-fit: cover;
    
  }
  .video-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }
  
  .content-overlay {
    position: absolute;
    bottom: 100px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    z-index: 2;
    
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .theme-gradient {
      background: linear-gradient(180deg,
       hsla(0, 0%, 7%, .6),
       hsla(0, 0%, 7%, .4),
       hsla(0, 0%, 7%, .2),
       hsla(0, 0%, 7%, .4),
        hsla(0, 0%, 7%, .6),
         hsla(0, 0%, 7%, .8),
          #000000);}
  
  .main-heading {
    font-size: 36px; 
    margin: 0;
    font-weight: 500;
  }
  @media (max-width: 992px) {
    .main-heading{
        font-size: 25px;
    } 
    .page-1 .main-heading{
    margin-top: 400px;
  }
    .content-overlay {
      position: absolute;
      bottom: 100px;
      left: 50%;
      width: 100%;
      top: 20%;
      transform: translateX(-50%);
      text-align: center;
      color: white;
      z-index: 2;
      
    }
    .sub-heading{
      font-size: 10px;
    }
}
@media (min-width: 768px) {
 
}
  
  