.background-dark {
  background-image: url("../Attachments/DARK\ BACKGROUND\ \(1\).png"),
    linear-gradient(rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.135));
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
}
.dark-background {
  background-color: black;
}
.phone-container {
  position: absolute;
  top: 10%;
  left: 60%;
  padding-right: 50px;
  transform-origin: center;
}

.phone-image {
  transition: transform 0.1s ease-out;
  max-width: 65%;
}

.text-lg {
  font-size: 1.25rem;
}

h1{
  font-size: 3.5rem;
  color: #33ab8f;
}
.heighter {
  height: 100vh;
  padding-top: 150px;
  padding: 6rem;
}

.font-semibold {
  font-weight: 600;
}

.font-extrabold {
  font-weight: 800;
}
.texts-container {
  max-width: 50%;
  margin-left: 5%;
}
.bg-dark {
  background-color: #000000bb;
  padding: 10px;
}

p {
  font-size: 1.5rem;
}
@media (max-width: 1400px) {
  .help-section {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .texts-container {
    max-width: inherit;
    margin-left: 5%;
  }
  .phone-container {
    display: none;
  }
  h1{
    font-size: 3rem;
    color: #33ab8f;
  }
  .heighter {
    height: 90vh;
    padding-top: 150px;
    padding: 0rem;
  }
}
@media (max-width: 740px) {
  .help-section {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .texts-container {
    max-width: inherit;
    margin-left: 5%;
  }
  .phone-container {
    display: none;
  }
  h1{
    font-size: 2.5rem;
    color: #33ab8f;
  }
  .heighter {
    height: 90vh;
    padding-top: 150px;
    padding: 0rem;
  }
}
@media (min-width: 1025px) and (max-width: 1400px){
    h1 {
        font-size: 3.5rem;
        color: #33ab8f;
      }
      .heighter {
        height: 60vh;
        padding-top: 150px;
        padding: 0rem;
      }
}
