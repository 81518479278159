
.counter-hold {
    margin: 0 auto;
    padding-bottom: 50px;
    text-align: center;
    color: #9b9b9b; 
  }
  

  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #9b9b9b;
    margin-bottom: 10px;
  }
  

  .section-subtitle {
    font-size: 1.25rem;
    color: #9b9b9b;
    margin-bottom: 20px;
  }
 
  .counter-wrap {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }

  .counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  
  .counter {
    font-size: 2rem;
    font-weight: bold;
    color: #9b9b9b;
  }
  
  .counter-sub {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #9b9b9b;
    margin-top: 10px;
  }
  

  @media (max-width: 992px) {
    .counter {
      font-size: 1.3rem;
    }
  
    .counter-sub {
      font-size: 0.875rem;
    }
  
    .counter-hold {
      padding-bottom: 30px;
    }
  
    .section-title {
      font-size: 2rem; 
    }
  
    .section-subtitle {
      font-size: 1rem; 
    }
  
    .counter-wrap {
      flex-direction: column; 
      align-items: center;
    }
  }
  