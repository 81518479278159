.column-1{
    width: 400px;
    margin-left: 10%;
    border-color: rgb(229 231 235 / 39%)
}
.column-2{
    width: 400px;
    margin-left: 20%;
    border-color: rgb(229 231 235 / 39%)
}

.column-3{
    width: 400px;
    margin-left: 30%;
    border-color: rgb(229 231 235 / 39%)
}
.phone{
    padding: 60px;
}

@media (max-width: 768px) {
    .phone{
       display: none;
    }
    .column-1{
        width: 90vw;
        margin-left: 4%;
        border-color: rgb(229 231 235 / 39%)
    }
    .column-2{
        width: 90vw;
        margin-left: 4%;
        border-color: rgb(229 231 235 / 39%)
    }
    .column-3{
        width: 90vw;
        margin-left: 4%;
        border-color: rgb(229 231 235 / 39%)
    }
    .why-holder{
    padding: 10px;
}
  }