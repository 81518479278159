.stacking-row {
  box-shadow: none;
  top: 100px;
  background-color: #141414;
  background-image: radial-gradient(circle farthest-side at 0 0, #17414d2b, #0000 60%), linear-gradient(#0f0e0ed9, #000000), url(https://cdn.prod.website-files.com/64c9523…/65d503a…_Grainy%20Effect.png);
  background-position: 0 0, 0 0, 0 0;
  border: 1px solid #ffffff26;
  border-radius: 16px;
  margin-bottom: 6rem;
  padding: 2rem;
  position: sticky;
  color: white;
}

.stacking-row.stacked {
  position: sticky;
  top: 100px;
  transform: translateY(0);
  z-index: 10;
}
.stacking-containers{
  padding: 0rem;
}


.header-image-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left {
  width: 50%;
  text-align: left;
}

.right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right img {
  min-width: 20rem;
  max-width: 20rem;
  border-radius: 10px;
}


.number-header {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 10px;
color: #f9fbfd80;
}


.subheading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #008080; 
  border-radius: 50%;
  margin-right: 8px;
}

.subheading-text {
  font-size: 1rem;
  font-weight: 500;
}


.bold-heading {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 15px;
}


.line-container {
  width: 100%;
  margin: 20px 0;
}

.line-separator {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}


.body-container {
  width: 100%;
}

.body-texts {
  font-size: 1rem;
  line-height: 1.6;

}

@media (max-width: 768px) {
  .header-image-container {
 display: none;
  }
  .body-texts {
    font-size: 0.9rem;
    line-height: 1.6;
    text-align: left;
  }

  .right img {
   display: none;
  }

  .number-header {
    font-size: 2rem;
  }

  .subheading-text {
    font-size: 1rem;
  }

  .bold-heading {
    font-size: 1.5rem;
  }
  .stacking-row {
    position: sticky;
    top: 30px;
    transform: translateY(0);
    z-index: 10;
  }
  .stacking-row.stacked {
    position: sticky;
    top: 40px;
    transform: translateY(0);
    z-index: 10;
  }
  .body-text {
    font-size: 1rem;
  }
}
